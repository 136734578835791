import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAcodaxPermissionSlug } from '../Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import DropdownAddButton from '../Buttons/DropdownAddButton';
import AddCustomer from '../Forms/AddCustomer';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import CustomerAdvancedSearch from 'components/cutomer_advanced_search/CustomerAdvancedSearch';
import { StoreContext } from 'context/Context';
function SearchAndSelectCustomer({
  handleFieldChange,
  name,
  error,
  value,
  setFormData,
  hideAddForm,
  showCustomerDetails,
  advancedSearch,
  enableBranchTransaction
}) {
  const Translate = useAxisproTranslate();
  const [addCustomer, setAddCustomer] = useState({
    show: false,
    name: ''
  });
  const { store } = useContext(StoreContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState('');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const permission = {
    create_customer: getAcodaxPermissionSlug('CS', 'create'),
    show_cs: getAcodaxPermissionSlug('CS', 'show')
  };

  const setItems = async name => {
    setLoading(true);
    try {
      let response = await axios.get(
        enableBranchTransaction
          ? 'sales/branch-customers-autocomplete'
          : 'sales/customerAutocomplete',
        {
          params: { name: name ? name : '' }
        }
      );
      let data = response && response.data.data ? response.data.data : [];
      if (data.length === 0) {
        setData(name);
        setOpen(true);
      } else {
        setOpen(false);
      }
      return data.map(item => ({
        label: item.customer_id + ' - ' + item.name,
        value: item.id
      }));
    } finally {
      setLoading(false);
    }
  };

  const { MenuList, Option } = components;

  const handleOpen = () => {
    setAddCustomer({
      show: true,
      name: data
    });
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter' && open) {
      setAddCustomer({
        show: true,
        name: data
      });
    }
  };

  const CustomMenuList = props => (
    <MenuList {...props}>
      {!hideAddForm && permission.create_customer && (
        <DropdownAddButton
          title={Translate('Add Customer')}
          show={handleOpen}
        />
      )}
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center p-3">
          Loading...
        </div>
      ) : (
        props.children
      )}
    </MenuList>
  );

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setItems('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  useEffect(() => {
    if (value?.value && defaultOptions.length > 0) {
      if (value?.value) {
        let dataExist = defaultOptions.filter(
          obj => obj['value'] === value?.value
        );
        if (dataExist?.length > 0) {
          return;
        } else {
          let newoptionSet = [value, ...defaultOptions];
          setDefaultOptions(newoptionSet);
        }
      }
    }
  }, [value, defaultOptions.length]);

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center w-100">
        {advancedSearch &&
          store?.globalConfiguration?.system_settings
            ?.enable_customer_advance_filter === '1' && (
            <CustomerAdvancedSearch handleCustomer={handleFieldChange} />
          )}
        <AsyncSelect
          cacheOptions
          loadOptions={setItems}
          defaultOptions={defaultOptions}
          onFocus={handleFocus}
          isClearable
          isSelected={true}
          isLoading={loading}
          isOptionSelected={item => item.value === value?.value}
          value={value}
          name={name ? name : 'customer'}
          placeholder=""
          onChange={handleFieldChange}
          className={`w-100 ${error && 'form-control ps-0 py-0 is-invalid'}'`}
          onKeyDown={e => handleKeyPress(e)}
          components={{ MenuList: CustomMenuList, Option }}
          styles={{
            menuPortal: provided => ({ ...provided, zIndex: 9999 }),
            menu: provided => ({
              ...provided,
              zIndex: 9999,
              fontFamily: 'sans-serif',
              fontSize: '12px',
              borderRadius: '0px',
              textTransform: 'capitalize',
              overflow: 'hidden'
            }),
            control: (base, state) => ({
              ...base,
              boxShadow: state.isFocused ? 0 : 0,
              borderRadius: '0px',
              border: '1px solid #b5b5b5',
              height: '10px'
            })
          }}
          menuPortalTarget={document.body}
        ></AsyncSelect>

        <AddCustomer
          show={addCustomer.show}
          onHide={() =>
            setAddCustomer({
              show: false,
              name: ''
            })
          }
          setFormData={setFormData}
          setData={setData}
          data={addCustomer.name}
        />
      </div>
      {showCustomerDetails && value && (
        <>
          {permission?.show_cs ? (
            <Button
              className="d-flex flex-row mt-1 align-items-center p-0"
              variant="white"
              onClick={handleCustomerDetailsArea}
              style={{ boxShadow: 'none' }}
            >
              <FontAwesomeIcon icon="user-circle" size="xs" className="me-1" />
              <span className="small text-primary">
                {Translate('View Customer Details')}
              </span>
            </Button>
          ) : (
            ''
          )}
          <CustomerDetails
            show={showCustomerDetailsArea}
            onHide={handleCustomerDetailsArea}
            value={value}
            showCustomerDetailsArea={showCustomerDetailsArea}
          />
        </>
      )}
    </>
  );
}

SearchAndSelectCustomer.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  children: PropTypes.any,
  setFormData: PropTypes.func,
  hideAddForm: PropTypes.bool,
  showCustomerDetails: PropTypes.bool,
  advancedSearch: PropTypes.bool,
  enableBranchTransaction: PropTypes.bool
};

export default SearchAndSelectCustomer;
