import React, { Fragment, useContext } from 'react';
import { Accordion, Badge, Button, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaEdit } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { DeleteContext } from 'context/Context';
import EstimationLineItemsAccordionViewCostItem from 'module/Production/Estimation/components/estimation-lineItems-accordion-view/EstimationLineItemsAccordionViewCostItem';
import EntriesPopUpLineItemAccordionViewTitleSection from './EntriesPopUpLineItemAccordionViewTitleSection';
import EntriesPopUpLineItemAccordionViewItemDescriptionSection from './EntriesPopUpLineItemAccordionViewItemDescriptionSection';

export default function EntriesPopUpLineItemAccordionView({
  type,
  entries,
  setFormData,
  handleSaveDraftData,
  taxIncluded,
  additionalCostIncluded,
  enableTaxGroup,
  enableBatchSection,
  batchIsEditable,
  batchEnabledItemFound,
  keyName
}) {
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);
  const showBatchSection = enableBatchSection && batchEnabledItemFound;
  let colSpan = [
    showBatchSection,
    enableTaxGroup,
    additionalCostIncluded
  ].reduce(
    (colSpan, item) => {
      if (item) {
        colSpan += 1;
      }
      return colSpan;
    },
    additionalCostIncluded ? 10 : 9
  );

  const targetKeyName = keyName || 'details';

  function handleEdit(event, ItemIndex) {
    event?.preventDefault();
    setFormData(prev => ({
      ...prev,
      [targetKeyName]: Array.isArray(prev?.[targetKeyName])
        ? prev[targetKeyName].map((entry, index) => {
            delete entry?.edit;
            if (index === ItemIndex) {
              entry.edit = true;
            }
            return entry;
          })
        : prev?.[targetKeyName]
    }));
  }

  function removeSelectedItem(dataArray, index) {
    if (Array.isArray(dataArray) && dataArray?.[index]) {
      dataArray.splice(index, 1);
    }
    return dataArray;
  }

  function handleRemove(event, ItemIndex) {
    event.preventDefault();
    configConfirm({
      type: 'CONFIG',
      payload: {
        show: true,
        title: Translate('Delete selected item'),
        message: Translate(
          'Are you sure you want to delete the selected line item?'
        ),
        submitButtonVariant: 'danger',
        buttonText: Translate('Delete'),
        cancelButtonVariant: 'secondary',
        onSubmit: event => {
          event?.preventDefault();

          setFormData(prev => {
            const newData = {
              ...prev,
              [targetKeyName]: Array.isArray(prev?.[targetKeyName])
                ? removeSelectedItem(prev[targetKeyName], ItemIndex)
                : prev?.[targetKeyName]
            };

            if (typeof handleSaveDraftData === 'function') {
              handleSaveDraftData({ draftableData: newData });
            }

            return newData;
          });

          configConfirm({
            type: 'RESET'
          });
        }
      }
    });
  }

  return (
    <Accordion>
      <Table
        bordered
        responsive
        size="sm"
        className="simple-table-animation mb-0"
      >
        <thead
          className="bg-light text-dark text-uppercase shadow-sm"
          style={{
            fontSize: '.76rem'
          }}
        >
          <EntriesPopUpLineItemAccordionViewTitleSection
            type={type}
            taxIncluded={taxIncluded}
            additionalCostIncluded={additionalCostIncluded}
            enableTaxGroup={enableTaxGroup}
            enableBatchSection={enableBatchSection && batchEnabledItemFound}
            batchIsEditable={batchIsEditable}
          />
        </thead>
        <tbody
          style={{
            fontSize: '.72rem',
            backgroundColor: '#f2f5f7'
          }}
        >
          {entries?.map((entry, index) => (
            <Fragment key={index}>
              {index > 0 ? (
                <EntriesPopUpLineItemAccordionViewTitleSection
                  type={type}
                  taxIncluded={taxIncluded}
                  additionalCostIncluded={additionalCostIncluded}
                  enableTaxGroup={enableTaxGroup}
                  enableBatchSection={
                    enableBatchSection && batchEnabledItemFound
                  }
                  batchIsEditable={batchIsEditable}
                  className="bg-light text-dark text-uppercase shadow-sm"
                />
              ) : null}
              <tr className="text-muted fw-bold">
                <td>
                  <Badge className="rounded">{index + 1}</Badge>
                </td>
                <td>{entry?.stock_id || '-'}</td>
                <td>
                  {entry?.title_ref?.label || entry?.item_display_name || '-'}
                </td>

                {type === 'ESTIMATION' ? (
                  <>
                    <td>
                      {entry?.dim_length
                        ? `${entry.dim_length} ${entry?.dimension_unit || ''}`
                        : '-'}
                    </td>
                    <td>
                      {entry?.dim_width
                        ? `${entry.dim_width} ${entry?.dimension_unit || ''}`
                        : '-'}
                    </td>
                    <td>
                      {entry?.dim_height
                        ? `${entry.dim_height} ${entry?.dimension_unit || ''}`
                        : '-'}
                    </td>
                    <td>{entry?.weight || '-'}</td>
                  </>
                ) : (
                  <>
                    <td>{entry?.unit_price_formatted || '-'}</td>
                    {additionalCostIncluded ? (
                      <td>{entry?.additional_cost_amount_formatted || '-'}</td>
                    ) : null}
                    <td>{entry?.unit_tax_formatted || '-'}</td>
                    {additionalCostIncluded ? (
                      <td>
                        {entry?.additional_cost_tax_amount_formatted || '-'}
                      </td>
                    ) : null}
                    <td>{entry?.discount_amount_formatted || '-'}</td>
                    {enableTaxGroup ? (
                      <td>{entry?.tax_group_name || '-'}</td>
                    ) : null}

                    {enableBatchSection && batchEnabledItemFound ? (
                      <td>
                        {entry?.batch_number_ref?.label ||
                          entry?.batch_number_display ||
                          entry.batch_number_display ||
                          '-'}
                      </td>
                    ) : null}
                  </>
                )}

                <td
                  className={type === 'ESTIMATION' ? 'border-end-0' : undefined}
                >
                  {entry?.quantity
                    ? `${entry.quantity} ${
                        entry?.unit_name_ref || entry?.unit_ref || ''
                      }`
                    : '-'}
                </td>
                {type !== 'ESTIMATION' ? (
                  <td className={'border-end-0'}>
                    {entry?.line_total_formatted || '-'}
                  </td>
                ) : null}

                <td className="border-start-0">
                  {typeof setFormData === 'function' ? (
                    <div className="d-flex flex-row align-items-center justify-content-end gap-1">
                      <Button
                        variant="transparent"
                        className="p-0 m-0 text-info outline-none shadow-none"
                        size="sm"
                        onClick={event => handleEdit(event, index)}
                      >
                        <FaEdit size={12} />
                      </Button>
                      <Button
                        variant="transparent"
                        className="p-0 m-0 text-danger outline-none shadow-none"
                        size="sm"
                        onClick={event => handleRemove(event, index)}
                      >
                        <FaTrash size={12} />
                      </Button>
                    </div>
                  ) : null}
                </td>
              </tr>

              <EntriesPopUpLineItemAccordionViewItemDescriptionSection
                title="ITEM DESCRIPTION"
                eventKey={'entryLineItemDescEvent' + index}
                description={entry?.item_description}
                colSpan={colSpan}
              />

              {['ES', 'ESTIMATION'].includes(type) ? (
                <>
                  <EstimationLineItemsAccordionViewCostItem
                    colSpan={colSpan}
                    title="MATERIALS / COSTS"
                    eventKey={'entryLineItemMaCostEvent' + index}
                    entries={entry?.costs}
                    subTotalFormatted={entry?.costs_net_amount_formatted}
                  />

                  <EstimationLineItemsAccordionViewCostItem
                    colSpan={colSpan}
                    title="ADDITIONAL COSTS"
                    eventKey={'entryLineItemAddCostEvent' + index}
                    entries={entry?.additional_costs}
                    subTotalFormatted={
                      entry?.additional_costs_net_amount_formatted
                    }
                  />
                </>
              ) : null}

              <tr
                className="bg-white"
                style={{
                  borderTop: '3px solid #e2e6eb',
                  borderBottom: 'none'
                }}
              >
                <td className="border-0 py-3" colSpan={colSpan}></td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
    </Accordion>
  );
}

EntriesPopUpLineItemAccordionView.propTypes = {
  type: PropTypes.oneOf(['FIXED-ASSET']).isRequired,
  entries: PropTypes.array,
  setFormData: PropTypes.func,
  handleSaveDraftData: PropTypes.func,
  taxIncluded: PropTypes.bool,
  additionalCostIncluded: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  batchEnabledItemFound: PropTypes.bool,
  keyName: PropTypes.string
};
