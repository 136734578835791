import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import classNames from 'classnames';
import {
  FaCheckCircle,
  FaEdit,
  FaInfoCircle,
  FaPlusCircle
} from 'react-icons/fa';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ItemCodeAutoComplete from 'components/form/ItemCodeAutoComplete';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import SelectItem from 'components/form/SelectItem';
import { FaArrowLeft } from 'react-icons/fa6';
import { fixedAssetPurchaseInvoiceEntryPopUpFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { showToast } from 'module/Common/Toast/toast';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import axios from 'axios';
import setNewFormData from 'helpers/setNewFormData';
import SelectTaxGroup from 'components/form/SelectTaxGroup';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import removeRefData from 'helpers/removeRefData';
import SelectBatch from 'components/form/SelectBatch';
import CommonSelect from 'components/form/CommonSelect';
import { fixedAssetType } from 'module/Common/Enum/enum';
import useFormSettingsHook from 'hooks/useFormSettingsHook';
import ShowInput from 'components/form-settings-offcanvas/ShowInput';

export default function EntriesPopUpFormModal({
  type,
  transaction_date,
  taxIncluded,
  enableTaxGroup,
  enableBatchSection,
  supplierDetails,
  transType,
  formSettingsTransType,
  transNumber,
  show,
  onHide,
  addCaseTitle,
  editCaseTitle,
  editingItemData,
  addToCart,
  keyName,
  setMainFormData,
  entries,
  setIncludeRoundOffAmount
}) {
  const Translate = useAxisproTranslate();
  const [shakeModal, setShakeModal] = useState(false);
  const [processingCartResult, setProcessingCartResult] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(
    editingItemData
      ? editingItemData
      : type === 'FIXED-ASSET'
      ? {
          ...fixedAssetPurchaseInvoiceEntryPopUpFormKeys,
          ...(enableTaxGroup
            ? { tax_group_id: '', tax_group_id_ref: null }
            : null),
          ...(enableBatchSection
            ? { batch_number: null, batch_number_ref: null }
            : null)
        }
      : {}
  );
  const { settings: FAP_settings } = useFormSettingsHook(formSettingsTransType);
  const handleFieldChange = (event, action) => {
    let keyName = !action ? event.target.name : action?.name;
    let newData = generateFormDataHelper(event, action, formData);
    if (keyName === 'discount_unit_ref') {
      newData.discount_amount = 0;
      newData.discount_percent = 0;
    }

    if (keyName === 'discount_percent' && newData?.discount_amount !== 0) {
      newData.discount_amount = 0;
    }

    if (keyName === 'discount_amount' && newData?.discount_percent !== 0) {
      newData.discount_percent = 0;
    }

    if (keyName === 'item_display_name') {
      if (enableTaxGroup) {
        let taxGroupDetails = {};
        if (supplierDetails?.tax_config === 'USE_ITEM_TAX') {
          taxGroupDetails = newData?.item_display_name_ref?.item;
        } else {
          const supplierTaxConfigTaxGroup =
            supplierDetails?.tax_config_tax_group;
          taxGroupDetails.tax_group_id = supplierTaxConfigTaxGroup?.id;
          taxGroupDetails.tax_group_name = supplierTaxConfigTaxGroup?.name;
        }

        newData.tax_group_id = taxGroupDetails?.tax_group_id;
        newData.tax_group_id_ref = {
          label: taxGroupDetails?.tax_group_name,
          value: taxGroupDetails?.tax_group_id
        };
      }

      if (enableBatchSection) {
        newData.has_batch_inventory = event?.item?.has_batch_inventory;
      }

      newData.stock_id = event?.item?.stock_id || '';
      newData.unit_price =
        editingItemData && editingItemData?.stock_id === event?.item?.stock_id
          ? editingItemData.unit_price
          : event?.item?.purchase_rate || '';
      newData.unit_ref = event?.item?.unit_name || '';
      newData.item_description =
        newData?.item_display_name_ref?.item?.item_description || '';
    }
    setFormData(newData);
  };

  const handleSubmit = async event => {
    event?.preventDefault();
    if (typeof setIncludeRoundOffAmount === 'function')
      setIncludeRoundOffAmount(true);

    const alreadyItemAdded = entries.some(
      items => items.stock_id === formData?.stock_id
    );
    if (!editingItemData && alreadyItemAdded && type === 'FIXED-ASSET') {
      return showToast(
        Translate('This Item Already Added, Please Choose Another Item')
      );
    }
    try {
      let errorMessage;
      if (!transaction_date) {
        errorMessage =
          'Transaction date is required, please select a valid date';
      }
      if (
        enableBatchSection &&
        Boolean(Number(formData?.has_batch_inventory)) &&
        !formData?.batch_number
      ) {
        errorMessage = 'Invalid batch, please select a valid batch';
      }

      if (errorMessage) {
        showToast(Translate(errorMessage));
        return;
      }
      if (addToCart) {
        setProcessingCartResult(true);
        // set nextIdRef value
        let nextIdRef = editingItemData?.nextIdRef || 1;
        if (!editingItemData && Array.isArray(entries)) {
          const entriesLength = entries?.length;
          if (entriesLength > 0) {
            const lastLineItem = entries[entriesLength - 1];
            if (lastLineItem) {
              nextIdRef = (parseInt(lastLineItem?.id_ref) || 1) + 1;
            }
          }
        }
        // set nextIdRef value end
        const arrayOfCartItems = setNewFormData({
          items: [
            {
              ...removeRefData(formData, 'object'),
              id_ref: nextIdRef,
              trans_date: transaction_date,
              tax_included: taxIncluded ? '1' : '0',
              quantity: 1
            }
          ],
          trans_type: transType,
          ...(transNumber ? { trans_no: transNumber } : null)
        });

        const cartApiResponse = await axios.post(
          'purchase/add-to-cart-bulk',
          arrayOfCartItems
        );

        const cartResult = cartApiResponse?.data?.data?.items?.[0];

        if (cartResult) {
          let targetKeyName = keyName || 'details';
          if (typeof cartResult?.id_ref === 'string') {
            cartResult.id_ref = parseInt(cartResult.id_ref);
          }
          setMainFormData(prev => ({
            ...prev,
            [targetKeyName]: editingItemData
              ? prev?.[targetKeyName]?.map(item => {
                  if (item?.edit) {
                    item = { ...formData, ...cartResult };
                  }
                  return item;
                })
              : [
                  ...(prev?.[targetKeyName] || null),
                  { ...formData, ...cartResult }
                ]
          }));
          typeof onHide === 'function' && onHide();
        }
      }
    } catch (error) {
      console.log(error);
      showToast(
        error?.response?.data?.message ||
          error?.message ||
          'Something went wrong, please refresh the page and try again.',
        'error'
      );
      setErrors({
        ...(error?.response?.data?.data?.errors?.[
          editingItemData
            ? parseInt(editingItemData?.id_ref)
            : entries?.length + 1
        ]?.[0] ?? null)
      });

      setShakeModal(true);
      setTimeout(() => {
        setShakeModal(false);
      }, 500);
    } finally {
      setProcessingCartResult(false);
    }
  };
  useEffect(() => {
    if (type === 'FIXED-ASSET') {
      setFormData(prev => ({
        ...prev,
        fixed_assets_type: 'vehicle',
        fixed_assets_type_ref: {
          label: 'VEHICLE',
          value: 'vehicle'
        }
      }));
    }
  }, [type]);
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size={'md'}
      className={classNames({
        'modal-shake': shakeModal
      })}
      style={{
        transition: 'width 2s linear'
      }}
    >
      <Modal.Header closeButton={!processingCartResult}>
        <Modal.Title
          as={'p'}
          className="d-flex flex-row align-items-center gap-1 text-uppercase fw-bold"
          style={{
            fontSize: '0.95rem'
          }}
        >
          {editingItemData ? (
            <>
              <FaEdit className="text-success" />
              <span>{Translate(editCaseTitle)}</span>
            </>
          ) : (
            <>
              <FaPlusCircle className="text-success" />
              <span>{Translate(addCaseTitle)}</span>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {processingCartResult ? (
          <LoadingScreen
            message={'Processing cart result, please wait'}
            className="py-3"
            spinnerProps={{
              style: {
                width: 12,
                height: 12
              }
            }}
          />
        ) : (
          <Form onSubmit={handleSubmit} className="p-3">
            {Object.keys(errors).length > 0 && (
              <ErrorAlert formError={errors} setFormError={setErrors} />
            )}
            <ShowInput
              show={
                type === 'FIXED-ASSET' &&
                (!FAP_settings ||
                  FAP_settings?.asset_type ||
                  Boolean(!formData?.fixed_assets_type?.trim()))
              }
            >
              <Form.Group className="mb-3" controlId="entryPopUpForm.itemCode">
                <Form.Label className="require-data">
                  {Translate('ASSET TYPE')}
                </Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <CommonSelect
                    name={'fixed_assets_type'}
                    onChange={handleFieldChange}
                    value={formData?.fixed_assets_type_ref}
                    option={fixedAssetType}
                    HideCloseButton
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput
              show={
                !FAP_settings ||
                FAP_settings?.item_code ||
                Boolean(!formData?.stock_id?.trim())
              }
            >
              <Form.Group className="mb-3" controlId="entryPopUpForm.itemCode">
                <Form.Label className="require-data">
                  {Translate('ITEM CODE')}
                </Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <ItemCodeAutoComplete
                    type="text"
                    name="stock_id"
                    keyName="item_name"
                    SelectItemKeyName="item_display_name"
                    value={formData?.stock_id || ''}
                    onChange={handleFieldChange}
                    filter_keys={{
                      exclude_kits: true,
                      include_fixed_asset: 1,
                      ...(type === 'FIXED-ASSET' && {
                        product_types: 'fixed_asset',
                        fixed_asset_type: formData?.fixed_assets_type
                      })
                    }}
                    className="px-2"
                  />

                  <FormErrorPopover
                    id="errors.stock_id"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={errors.stock_id}
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput
              show={
                !FAP_settings ||
                FAP_settings?.item_name ||
                Boolean(!formData?.item_display_name?.trim())
              }
            >
              <Form.Group className="mb-3" controlId="entryPopUpForm.itemName">
                <Form.Label className="require-data">
                  {Translate('ITEM NAME')}
                </Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <div className="flex-fill">
                    <SelectItem
                      name="item_display_name"
                      keyName="item_name"
                      advanceSearch
                      showFilterIcon={type === 'FIXED-ASSET' ? true : false}
                      fixedAssets={type === 'FIXED-ASSET' ? true : false}
                      advanceSearchType="purchase"
                      placeholder={Translate('Search...')}
                      style={{ borderRadius: 'none' }}
                      filter_keys={{
                        exclude_kits: true,
                        include_fixed_asset: 1,
                        ...(type === 'FIXED-ASSET' && {
                          product_types: 'fixed_asset',
                          fixed_asset_type: formData?.fixed_assets_type,
                          exclude_purchased_fixed_assets: '1'
                        })
                      }}
                      value={formData?.item_display_name_ref}
                      handleFieldChange={handleFieldChange}
                    />
                  </div>
                  <FormErrorPopover
                    id="errors.item_display_name"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={errors.item_display_name}
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput show={!FAP_settings || FAP_settings?.item_description}>
              <Form.Group
                className="mb-3"
                controlId="entryPopUpForm.itemDescription"
              >
                <Form.Label>{Translate('ITEM DESCRIPTION')}</Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="item_description"
                    onChange={handleFieldChange}
                    value={formData?.item_description || ''}
                    className="px-2"
                  />

                  <FormErrorPopover
                    id="errors.item_description"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={errors.item_description}
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput
              show={
                !FAP_settings ||
                FAP_settings?.unit_price ||
                Boolean(!formData?.unit_price?.trim())
              }
            >
              <Form.Group
                className="mb-3"
                controlId="entryPopUpForm.unit_price"
              >
                <Form.Label className="require-data d-flex flex-row align-items-center">
                  <span>{Translate('UNIT PRICE')}</span>
                  {taxIncluded ? (
                    <Badge bg="warning" className="ms-1 rounded">
                      {Translate('Inc Tax')}
                    </Badge>
                  ) : null}
                </Form.Label>

                <div className="d-flex flex-row align-items-center">
                  <Form.Control
                    type="number"
                    step="0.1"
                    min={0}
                    name="unit_price"
                    onChange={handleFieldChange}
                    value={formData?.unit_price || ''}
                    className="px-2"
                  />

                  <div
                    className={classNames(
                      'bg-secondary text-light align-self-stretch d-flex align-items-center justify-content-center px-2 fw-bold',
                      {
                        small: !!formData?.unit_ref
                      }
                    )}
                  >
                    {formData?.unit_ref ? (
                      'PCS'
                    ) : (
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            {Translate(
                              'Please select an item and its unit name will be displayed here'
                            )}
                          </Tooltip>
                        }
                      >
                        <span>
                          <FaInfoCircle />
                        </span>
                      </OverlayTrigger>
                    )}
                  </div>

                  <FormErrorPopover
                    id="errors.unit_price"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={errors.unit_price}
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput
              show={
                !FAP_settings ||
                FAP_settings?.discount ||
                Boolean(!formData?.discount_amount)
              }
            >
              <Form.Group className="mb-3" controlId="entryPopUpForm.discount">
                <Form.Label
                  className={`${type === 'FIXED-ASSET' ? '' : 'require-data'}`}
                >
                  {type === 'FIXED-ASSET'
                    ? Translate('DISCOUNT')
                    : Translate('DISCOUNT / UNIT ')}
                </Form.Label>

                <div className="d-flex flex-row align-items-center">
                  <div className="d-flex flex-row flex-fill">
                    <Form.Control
                      className="w-100 px-2"
                      type="number"
                      step="0.1"
                      min={0}
                      max={
                        formData?.discount_unit_ref === '%'
                          ? 100
                          : formData?.unit_price > 0
                          ? formData.unit_price
                          : 0
                      }
                      name={
                        formData?.discount_unit_ref === '%'
                          ? 'discount_percent'
                          : 'discount_amount'
                      }
                      onChange={handleFieldChange}
                      value={
                        formData[
                          formData?.discount_unit_ref === '%'
                            ? 'discount_percent'
                            : 'discount_amount'
                        ]
                      }
                    />
                    <Form.Select
                      className="border-start-0"
                      aria-label="Discount unit"
                      style={{ width: '80px' }}
                      name="discount_unit_ref"
                      onChange={handleFieldChange}
                      value={formData?.discount_unit_ref || ''}
                    >
                      <option value="$">$</option>
                      <option value="%">%</option>
                    </Form.Select>
                  </div>

                  <FormErrorPopover
                    id="errors.discount"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={
                      errors?.discount_amount || errors?.discount_percent
                    }
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput
              show={
                enableBatchSection &&
                Boolean(Number(formData?.has_batch_inventory))
              }
            >
              <Form.Group className="mb-3" controlId="entryPopUpForm.batch">
                <Form.Label className="require-data">
                  {Translate('Batch')}
                </Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <div className="flex-fill">
                    <SelectBatch
                      value={formData.batch_number_ref}
                      stock_id={formData?.stock_id}
                      handleFieldChange={handleFieldChange}
                      name="batch_number"
                      keyName="batch_number"
                      placeholder={Translate('Search...')}
                      isDisabled={!formData?.stock_id}
                      withAddButton
                    />
                  </div>

                  <FormErrorPopover
                    id="errors.batch_number"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={errors.batch_number}
                  />
                </div>
              </Form.Group>
            </ShowInput>

            <ShowInput show={enableTaxGroup}>
              <Form.Group className="mb-3" controlId="entryPopUpForm.taxGroup">
                <Form.Label className="require-data">
                  {Translate('Tax Group')}
                </Form.Label>
                <div className="d-flex flex-row align-items-center">
                  <div className="flex-fill">
                    <SelectTaxGroup
                      withTaxDetails
                      name="tax_group_id"
                      placeholder={Translate('Search...')}
                      value={formData?.tax_group_id_ref}
                      onChange={handleFieldChange}
                      isClearable={false}
                    />
                  </div>

                  <FormErrorPopover
                    id="errors.tax_group_id"
                    totalErrorCount={Object.keys(errors).length}
                    errorMessage={errors.tax_group_id}
                  />
                </div>
              </Form.Group>
            </ShowInput>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="danger"
          className="text-uppercase d-flex align-items-center gap-1 py-1"
          onClick={onHide}
          disabled={processingCartResult}
        >
          <FaArrowLeft size={12} />
          <small>{Translate('Cancel')}</small>
        </Button>
        <Button
          size="sm"
          variant="success"
          className="text-uppercase d-flex align-items-center gap-1 py-1"
          type="submit"
          onClick={handleSubmit}
          disabled={processingCartResult}
        >
          <small>{Translate(editingItemData ? 'Update' : 'Add')}</small>
          {editingItemData ? (
            <FaCheckCircle size={12} />
          ) : (
            <FaPlusCircle size={12} />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EntriesPopUpFormModal.propTypes = {
  type: PropTypes.oneOf(['FIXED-ASSET']).isRequired,
  transaction_date: PropTypes.string,
  taxIncluded: PropTypes.bool,
  enableTaxGroup: PropTypes.bool,
  supplierDetails: PropTypes.object,
  transType: PropTypes.string,
  formSettingsTransType: PropTypes.string,
  transNumber: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  addCaseTitle: PropTypes.string,
  editCaseTitle: PropTypes.string,
  editingItemData: PropTypes.object,
  addToCart: PropTypes.bool,
  keyName: PropTypes.string,
  setMainFormData: PropTypes.func,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  entries: PropTypes.array,
  setIncludeRoundOffAmount: PropTypes.func
};
